import React, { useState } from "react";
import "./home.css";
import { useNavigate } from "react-router-dom";
import Data from "../../Data.json";
import Modal from "../../components/Modal/Modal";
import options from "../../options.json";

const Home = () => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState(""); //seçilen kişinin numarası
  const [password, setPassword] = useState("");
  const [passwordReceived, setPasswordReceived] = useState("");
  const [forWho, setForWho] = useState("");
  const [reasonPassword, setReasonPassword] = useState("");
  // const [passwordType, setPasswordType] = useState("");
  const [price, setPrice] = useState("");

  const handleChangePerson = (event) => {
    setSelected(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      selected === "" ||
      password === "" ||
      passwordReceived === "" ||
      forWho === "" ||
      reasonPassword === "" ||
      // passwordType === "" ||
      selectedOption === ""
    ) {
      alert("Lütfen Tüm Alanları Doldurduğunuzdan Emin Olunuz.");
      return navigate("/");
    } else {
      try {
        const response = await fetch(
          "https://mobrespospay.com/Mobsifreservisweb/Mobsifreservis.svc/MOB_RES_Ekle",
          {
            method: "POST",
            headers: {
              Authorization: "Basic " + btoa("MOBRES:14321MOBRES"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Header_Info: {
                UserName: "MOBRES",
                Password: "14321MOBRES",
              },
              SerialNo: "MOBRES14321MOBSIFREVER",
              Secilenkisi: selected,
              Kimistiyor: passwordReceived,
              Kimicinistiyor: forWho,
              Neicinistiyor: reasonPassword,
              Sifreturu: selectedOption,
              Ucretaciklama: price,
             
            }),
          }
        );
        if (!response.ok) {
          throw new Error(`Error! status: ${response.status}`);
        }
        const result = await response.json();
        // console.log('result',result);

        if (result.ResultCode === 0) {
          sendMessage(result.Sifre);
        } else {
          alert("İşlem başarısız.");
        }
      } catch (err) {
        console.log(err);
        alert("Mesaj gönderilemedi.!");
      }
    }
  };
  const [selectedOption, setSelectedOption] = useState(""); //şifre türleri

  const [isModalOpen, setIsModalOpen] = useState(false);
 


  const deviceOptions = [
    { id: "android1", label: "Android El Terminali" },
    { id: "ios1", label: "iOS Patron İzleme" },
    { id: "android2", label: "Android Patron İzleme" },
    { id: "diğer", label: "Diğer" },
    { id: "ios2", label: "iOS El Terminali" },
  ];

  const handleCheckboxClick = () => {
    setIsModalOpen(true);
  };

  const handleOptionSelect = (option) => {
  
    setSelectedOption(option);
    setIsModalOpen(false);
  };


  const sendMessage = (yourPassword) => {
    const phoneNumber = selected; // Gönderilecek numara

    const message = `Seçilen Kişi: ${selected}\n Şifre: ${password}\n Şifre İsteyen: ${passwordReceived}\n Kim İçin İstiyor: ${forWho}\n Sebebi: ${reasonPassword}\n Şifre Türü: ${selectedOption}\n Ücret Açıklaması: ${price}
   Gelen Şifreniz: ${yourPassword}`;
    // Gönderilecek mesaj

    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div className="container py-3">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className=" col-md-6 col-lg-6 mb-5">
          <form>
            <div
              className="card shadow-2-strong"
              style={{ borderradius: "1rem" }}
            >
              {/* deneme 1*/}
              <div className="card-body">
                <div className="mt-1">
                  <label htmlFor="phonebook">Bir Kişi Seç</label>
                </div>
                <div className="mt-2">
                  <select
                    id="phonebook"
                    value={selected}
                    onChange={handleChangePerson}
                  >
                    <option value="" disabled>
                      Bir Kişi Seç
                    </option>
                    {Data.map((item, index) => (
                      <option key={index} value={item.phone}>
                        {item.name} - {item.phone}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  {selected && (
                    <div className="mt-2">
                      <p>Seçilen Kişi: {selected}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-body">
                <div className="form-outline mb-2">
                  <label htmlFor="password" className="mb-2">
                    Şifre
                  </label>
                  <input
                    maxLength={50}
                    required
                    name="password"
                    type="password"
                    className="form-control form-control-lg"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="form-outline mb-2">
                  <label htmlFor="passwordReceived" className="mb-2">
                    Kim İstiyor?
                  </label>
                  <input
                    maxLength={50}
                    required
                    name="passwordReceived"
                    type="text"
                    className="form-control form-control-lg"
                    value={passwordReceived}
                    onChange={(e) => setPasswordReceived(e.target.value)}
                  />
                </div>
                <div className="form-outline mb-2">
                  <label htmlFor="forWho" className="mb-2">
                    Kim İçin İstiyor?
                  </label>
                  <input
                    maxLength={50}
                    required
                    name="forWho"
                    type="text"
                    className="form-control form-control-lg"
                    value={forWho}
                    onChange={(e) => setForWho(e.target.value)}
                  />
                </div>

                <div className="form-outline mb-2">
                  <label htmlFor="reason" className="mb-2">
                    Ne İçin İstiyor?
                  </label>
                  <input
                    value={reasonPassword}
                    required
                    name="reason"
                    type="text"
                    className="form-control form-control-lg"
                    onChange={(e) => setReasonPassword(e.target.value)}
                  />
                </div>

                <div className="form-outline mb-2">
                  <label htmlFor="passwordType" className="mb-2">
                    Şifre Türü Nedir?
                  </label>

                  <div
                    className="form-control form-control-lg d-flex justify-content-between align-items-center"
                    onClick={handleCheckboxClick}
                  >
                    <span className="chosenPassword">
                      {selectedOption ? selectedOption : "Bir şifre türü seçin"}
                    </span>
                    <input type="checkbox" className="form-check-input" />
                  </div>

                  {isModalOpen && (
                    <Modal
                      isOpen={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                    >
                      <div className="modal-content">
                        <h4 className="m-4">Şifre Türünü Seçiniz</h4>

                        <ul className="options-list">
                          {options.map((option) => (
                            <li
                              key={option.id}
                              className="passwordText"
                              onClick={() => handleOptionSelect(option.label)}
                            >
                              {option.label}
                            </li>
                          ))}
                        </ul>
                    
                      </div>
                    </Modal>
                  )}
                </div>

                <div className="form-outline mb-2">
                  <label htmlFor="price" className="mb-2">
                    Ücret Açıklaması
                  </label>
                  <textarea
                    maxLength={30}
                    required
                    name="price"
                    type="text"
                    className="form-control form-control-lg"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
                <button
                  onClick={handleSubmit}
                  className="btn btn-primary btn-lg mt-1 w-100 text-white"
                  type="submit"
                >
                  Gönder
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;
