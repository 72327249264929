import React from "react";
import image from '../../assets/images/mobresLight.jpeg';
import './header.css';

const Header = () => {
  return (
    <div className="header">
      <img src={image} alt="korfez" className="img" />
    </div>
  );
};

export default Header;
