import React, { useState} from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import "./login.css";
import Header from "../../components/Header/Header";

const Wrapper = styled.div`
  max-width: 350px;
  min-height: 200px;
  margin: 20px auto;
  padding: 40px 30px 30px 30px;
  background-color: #283035;
  border-radius: 15px;
  box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff;
  overflow: hidden;
  margin-top: -30px;
  position: relative;

  .logo {
    width: 100%;
    margin: auto;
  }

  .logo img {
    width: 100%;
    height: 100px;
    object-fit: fill;
    margin-bottom: 20px;
    // border-radius: 50%;
    // box-shadow: 0px 0px 3px #5f5f5f, 0px 0px 0px 5px #ecf0f3,
    //   8px 8px 15px #a7aaa7, -8px -8px 15px #fff;
  }

  & .name {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.3px;
    padding-left: 10px;
    color: #555;
  }

  & .form-field input {
    width: 100%;
    height: 50px;
    display: block;
    border: none;
    outline: none;
    background: none;
    font-size: 1.2rem;
    font-style: italic;
    padding: 10px 15px 10px 10px;
    text-transform: capitalize;
    // border: 1px solid red;
    border-radius: 20px;
    align-items: center;
  }

  & .form-field {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    background-color: #fff;
    // padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;
  }

  & .form-field .far {
    color: #555;
    margin-left: 10px;
    margin-right: 5px;
  }
  & .date {
    margin-left: 10px;
    margin-right: 5px;
  }

  & .btn {
    box-shadow: none;
    width: 100%;
    height: 40px;
    background-color: #303a40;
    color: #fff;
    border-radius: 25px;
    box-shadow: 3px 3px 3px #b1b1b1, -1px -1px 2px #fff;
    // letter-spacing: 1.3px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
  }

  & .second_btn {
    color: #fff;
    background: none;
    padding-left: 3px;
    font-size: 16px;
    cursor: pointer;
  }
  & .check {
    width: 30px;
    height: 25px;
  }

  & .btn:hover {
    background-color: blue;
  }
  & .second_btn:hover {
    color: lightgrey;
  }
  & .second_icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background-color: #fff;
    border-radius: 50px;
  }

  & .btn_icon {
    width: 23px;
    height: 23px;
    margin-right: 10px;
    background-color: #fff;
    border-radius: 50px;
  }
  & a {
    text-decoration: none;
    font-size: 0.8rem;
    color: #03a9f4;
  }
  & .label {
    color: #fff;
    margin-left: 10px;
    margin-bottom: 5px !important;
  }
  & a:hover {
    color: #039be5;
  }

  @media (max-width: 380px) {
    .& {
      margin: 30px 20px;
      padding: 40px 15px 15px 15px;
    }
  }
`;

const Login = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");

  const handleSubmit = async () => {

    if (password === "12345") {
      navigate("/home");
    }else{
      alert('Şifre yanlış,lütfen tekrar deneyiniz.');
      setPassword("");
    }
  };



  return (
    <div className="loginContainer">
      <Header />
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <div className="form-field">
            <span className="far fa-user"></span>
            <input
              name="password"
              type="password"
              placeholder="Şifreyi giriniz."
              required
              onChange={(e) => setPassword(e.target.value)}
            />
           
          </div>

          <button type="submit" className="btn mt-3">
            Giriş Yap
          </button>
        </form>
      </Wrapper>
    </div>
  );
};

export default Login;
